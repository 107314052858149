import { useState } from 'react'
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS, CallBackProps } from 'react-joyride';
import { Image } from 'primereact/image';
import reassignment from './../../assets/Reassignment_Joyride.jpg';
import { useUpdateSkipIntroMutation } from '../../store/settingApi';
import { toast } from 'react-toastify';

const CustomJoyride = () => {
  
  const [skipIntro, { isLoading }] = useUpdateSkipIntroMutation();
  const [run, setRun] = useState(true);
  const steps = [
    {
      target: '.child-menu li:nth-child(1)',
      content: 'A treasure map of all IT(x) related costs per Cost Center, because who doesn’t love a good budget adventure?',
      disableBeacon: true
    },
    {
      target: '.search-section',
      content: 'You can search for Cost Center, Service ID, name, SA-ID, and maybe even your lost socks!',
    },
    {
      target: 'div.cost-center-card',
      content: (
        <div>
          'A detailed overview of all IT(x) costs associated with your Cost Center, helping you keep track of every expense!'
          <Image className='flex align-items-center justify-content-center' src={reassignment} alt="Service Reassignment" preview width="700px"/>
        </div>
      ),
      placement: 'top' as const,
      styles: {
        options: {
          width: "60vw",
          maxWidth: "60vw",
          height: '60vh',
          minHeight: '60vh !important',
        }
      }
    },
    {
      target: '.child-menu li:nth-child(2)',
      content: 'A grand tour of all Workplace related costs per Cost Center, where every penny has its own story to tell.',
    },
    {
      target: '#menu li:nth-child(3)',
      content: 'You can maintain settings such as Mail Notification or Deputy Assignment, because everyone needs a trusty sidekick!',
    },
    {
      target: '#notifications',
      content: 'You can download the latest User Manual, the ultimate guide to becoming a wizard in the application!',
    },
    {
      target: '#atlwdg-trigger',
      content: 'You can contact support for questions or ideas, because sometimes you just need a superhero to save the day!',
      placement: 'left' as const,
    }
  ];

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { action, index, origin, status, type } = data;
    if (action === ACTIONS.CLOSE) {
      setRun(false);
    }
    if (action ===  ACTIONS.SKIP) {
      await skipIntro(true).unwrap()
        .then((payoad: any) => {
            toast.success('Intro Skipped Successfully');
        })
        .catch((e: any) => {
            toast.error(e.data.message);
        });
    }
  }

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        steps={steps}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 1000,
            primaryColor: '#3698DC'
          }
        }}
      />
    </>
  )
}

export default CustomJoyride;
